import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Layout from 'components/Layout'
import Rows from 'components/Rows'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import AppDownloadModal from 'features/app/components/AppDownloadModal'
import Challenge from 'features/user/components/Challenge'
import * as lessonsQuery from 'libs/lessons-query'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Dashboard.module.scss'
import DashboardActivity from './components/DashboardActivity'
import DashboardLessonsFavorite from './components/DashboardLessonsFavorite'
import DashboardLessonsRecommended from './components/DashboardLessonsRecommended'
import DashboardLiveLessons from './components/DashboardLiveLessons'
import DashboardToday from './components/DashboardToday'

export default function Dashboard() {
  const globalContext = useGlobalContext()
  const [data, setData] = React.useState<null | any>(null)
  const { data: lessonsFavorite, isLoading } = lessonsQuery.useUserLessonsFavorite()
  const lessonsFavoriteData = lessonsFavorite?.data

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getData() {
      const response = await userApiRequest.getUserDashboard()

      if (response.statusCode !== 200) return
      setData(response.data)
    }
    getData()
  }, [globalContext.user])

  const hasLessonsFavorite = !isLoading && lessonsFavoriteData && !_.isEmpty(lessonsFavoriteData)

  return (
    <Layout className="Dashboard" data-test-id="dashboard">
      <AppDownloadModal />
      <View color="grey" className={styles.view}>
        <Container flush size="xxlarge">
          <Rows size="large">
            <DashboardToday />
            <DashboardActivity data={data?.activity} />
            <Challenge from={paths.HOME_AUTHED} />
            <DashboardLiveLessons data={data?.liveLessons} />
            <DashboardLessonsFavorite data={lessonsFavoriteData} isLoading={isLoading} />
            {!hasLessonsFavorite && <DashboardLessonsRecommended />}
          </Rows>
        </Container>
      </View>
    </Layout>
  )
}
