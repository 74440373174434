import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import AppDownloadModal from 'features/app/components/AppDownloadModal'
import AssessmentStrengthLowerBodyModals from 'features/assessments/components/AssessmentStrengthLowerBodyModals'
import ProgramFollowUpModal from 'features/program/components/ProgramFollowUpModal'
import SubscriptionUpgradeSection from 'features/user/components/SubscriptionUpgradeSection'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { useUserCheckin } from 'libs/checkin-query'
import * as programLib from 'libs/program'
import { useProgram } from 'libs/program-query'
import * as user from 'libs/user'
import ProgramDynamic from './components/ProgramDynamic'
import ProgramStatic from './components/ProgramStatic'

export default function Program() {
  useEventPageVisited('Program')
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const programType = program?.programType

  const { data: programFollowUpCheckinData, mutate: mutateProgramFollowUpCheckinData } =
    useUserCheckin(
      {
        type: 'program_follow_up',
        activeOnly: true,
      },
      {
        dependencies: [programData],
        revalidateOnFocus: false,
      }
    )
  const programFollowUpCheckin = programFollowUpCheckinData?.data
  const [showProgramFollowUpModal, setShowProgramFollowUpModal] = React.useState(false)

  React.useEffect(() => {
    if (programData?.statusCode === 422) {
      navigate(paths.PROGRAM_BUILD, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programData])

  React.useEffect(() => {
    if (
      programFollowUpCheckin &&
      !globalContext.isVideoPlaying &&
      !globalContext.programFollowUpCheckinSubmitted
    ) {
      setShowProgramFollowUpModal(true)
    }
  }, [
    globalContext.isVideoPlaying,
    globalContext.programFollowUpCheckinSubmitted,
    programFollowUpCheckin,
  ])

  const commonProps = {
    className: 'Program',
  }

  if (user.isFreeUser(globalContext.user)) {
    return (
      <Layout {...commonProps}>
        <View>
          <Container size="medium">
            <Box align="center" color="white">
              <SubscriptionUpgradeSection />
            </Box>
          </Container>
        </View>
      </Layout>
    )
  }

  if (!program) {
    return (
      <Layout {...commonProps}>
        <View>
          <Loader overlay={false} />
        </View>
      </Layout>
    )
  }

  return (
    <Layout {...commonProps}>
      <AppDownloadModal />
      {!showProgramFollowUpModal && <AssessmentStrengthLowerBodyModals />}
      {showProgramFollowUpModal && (
        <ProgramFollowUpModal
          isOpen={showProgramFollowUpModal}
          programFollowUpCheckin={programFollowUpCheckin}
          onCheckinSuccess={() => {
            setShowProgramFollowUpModal(false)
            mutateProgramFollowUpCheckinData(null, false)
          }}
        />
      )}
      {programType === programLib.TYPE_DYNAMIC && <ProgramDynamic />}
      {programType === programLib.TYPE_STATIC && <ProgramStatic />}
    </Layout>
  )
}
