import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import type { Component } from 'constants/types'
import styles from './ProgramLayout.module.scss'

type Props = {
  blocks: React.ReactElement
  children: React.ReactNode
  header: React.ReactElement
  loading?: boolean
  overview: React.ReactElement
} & Component

export default function ProgramLayout({
  blocks,
  children,
  className,
  header,
  loading,
  overview,
}: Props) {
  if (loading) {
    return (
      <View>
        <Loader overlay={false} />
      </View>
    )
  }

  return (
    <div className={classNames('ProgramLayout', className)}>
      {header}
      <View color="grey" className={styles.view}>
        <Container flush size="xxlarge">
          <Row size="large">
            <div className={styles['overview-and-blocks']}>
              {React.cloneElement(overview, {
                className: classNames(styles.overview, overview.props?.className),
              })}
              {React.cloneElement(blocks, {
                className: classNames(styles.blocks, blocks.props?.className),
              })}
            </div>
          </Row>
          <Text element="p" flush size="small">
            The Program Content is not intended to and does not provide medical advice. All use of
            equipment shown is optional, and all exercises can be done without any equipment.
          </Text>
        </Container>
      </View>
      {children}
    </div>
  )
}
