import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonBox from 'features/lesson/components/LessonBox'
import useEventPageVisited from 'hooks/use-event-page-visited'
import type { Lesson } from 'libs/lesson'
import { useUserLessonsFavorite } from 'libs/lessons-query'
import * as user from 'libs/user'
import styles from './LessonsFavorite.module.scss'

export default function LessonsFavorite() {
  useEventPageVisited('Favorite classes')
  const globalContext = useGlobalContext()
  const { data: lessonsFavoriteData, isLoading: isLessonsFavoriteLoading } =
    useUserLessonsFavorite()

  return (
    <Layout className="LessonsFavorite">
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <HeaderSubheader title="My saved classes" />
      <View color="grey">
        <Container flush size="xxlarge">
          {isLessonsFavoriteLoading ? (
            <Loader overlay={false} />
          ) : _.isEmpty(lessonsFavoriteData?.data) ? (
            <Title
              subtext={`Add any Explore ${!user.isFreeUser(globalContext.user) ? 'or Program' : ''} class to your favorites to watch later`}
              actions={
                !user.isFreeUser(globalContext.user) && (
                  <Button to={paths.PROGRAM} color="white">
                    Go to program
                  </Button>
                )
              }
              color="grey"
              flush>
              Fill this page with your favorite classes
            </Title>
          ) : (
            <div className={styles.lessons}>
              {lessonsFavoriteData.data?.map((item: Lesson) => (
                <LessonBox data={item} key={item.id} />
              ))}
            </div>
          )}
        </Container>
      </View>
    </Layout>
  )
}
